import Vue from 'vue'
import Vuex from 'vuex'
import api from "@/http/api";
Vue.use(Vuex);
const state = {
    userData: {},//用户信息
    sidebarData:[],
    cartData:[],//购物车信息
    cartNum:0,
    navData:[
        {
            id:1,
            name:"火热推荐",
            url:"/mall/goodsList",
            hot:1
        },{
            id:2,
            name:"建筑场景",
            url:"/scene/newScene",
            hot:2
        },{
            id:3,
            name:"修缮场景",
            url:"/scene/oldScene"
        },{
            id:4,
            name:"设计与施工技术",
            url:"/build/index"
        },{
            id:5,
            name:"果尔佳服务",
            url:"/serviceSystem/home"
        }
    ],
    navService:[
        {
            id:1,
            name:"建筑服务",
            url:"/serviceSystem/construct"
        },
        {
            id:2,
            name:"技能培训",
            url:"/serviceSystem/skill"
        },
        {
            id:3,
            name:"加盟申请",
            url:"/serviceSystem/join"
        },
        {
            id:4,
            name:"售后服务",
            url:"/serviceSystem/afterSales"
        },
        {
            id:6,
            name:"服务体系",
            url:"/serviceSystem/service"
        },
    ],
    broadside:{},//用户中心--存储当前显示侧边菜单
    orderNum:{},//订单数量
    searchValue:"",//搜索
    isVerification:false,
    skillsList:[],//技能培训列表
    platformInfo:{},//平台信息
};
//用户信息
if (
    localStorage.getItem("userData") != null &&
    localStorage.getItem("userData") != undefined
) {
    state.userData = JSON.parse(localStorage.getItem("userData"));
}
//平台信息
if (
    localStorage.getItem("platformInfo") != null &&
    localStorage.getItem("platformInfo") != undefined
) {
    state.platformInfo = JSON.parse(localStorage.getItem("platformInfo"));
}
//商品分类
if (
    sessionStorage.getItem("sidebarData") != null &&
    sessionStorage.getItem("sidebarData") != undefined
) {
    state.sidebarData = JSON.parse(sessionStorage.getItem("sidebarData"));
}else{
    api.getProductClassification().then(res=>{
        if(res.code == 0){
            state.sidebarData = res.data
             sessionStorage.setItem("sidebarData",JSON.stringify(res.data)) 
        }
    })
}

//订单数量
if (
    sessionStorage.getItem("orderNum") != null &&
    sessionStorage.getItem("orderNum") != undefined
) {
    state.orderNum = JSON.parse(sessionStorage.getItem("orderNum"));
}

//购物车列表
if (
    localStorage.getItem("cartData") != null &&
    localStorage.getItem("cartData") != undefined
) {
    state.cartData = JSON.parse(localStorage.getItem("cartData"));
}
//购物车数量
if (
    localStorage.getItem("cartNum") != null &&
    localStorage.getItem("cartNum") != undefined
) {
    state.cartNum = JSON.parse(localStorage.getItem("cartNum"));
}
// 用户中心-侧边菜单当前显示存储
if (
    sessionStorage.getItem("broadside") != null &&
    sessionStorage.getItem("broadside") != undefined
) {
    state.broadside = JSON.parse(sessionStorage.getItem("broadside"));
}

// 搜索
if (
    sessionStorage.getItem("searchValue") != null &&
    sessionStorage.getItem("searchValue") != undefined
) {
    state.searchValue = JSON.parse(sessionStorage.getItem("searchValue"));
}

// 技能培训列表
if(
    sessionStorage.getItem("skillsList") != null &&
    sessionStorage.getItem("skillsList") != undefined
){
    state.skillsList = JSON.parse(sessionStorage.getItem("skillsList"));
}
const mutations = {

    //用户信息
    getUserData(state,data){
        state.userData = data
        localStorage.setItem("userData",JSON.stringify(data)) 
    },
    //平台信息
    getPlatformInfo(state,data){
        state.platformInfo = data
        localStorage.setItem("platformInfo",JSON.stringify(data)) 
    },
    // //获取首页商品分类侧边栏
    // getSidebarData(state,data){
    //      state.sidebarData = data
    //      sessionStorage.setItem("sidebarData",JSON.stringify(data)) 
    // },

    //获取购物车列表
    getcartData(state,data){
         state.cartData = data
         localStorage.setItem("cartData",JSON.stringify(data)) 
    },
    //获取购物车数量
    getcartNum(state,data){
         state.cartNum = data
         localStorage.setItem("cartNum",JSON.stringify(data)) 
    },
    // 用户中心显示侧边菜单
    getBroadside(state,data){
        state.broadside = data
        sessionStorage.setItem("broadside",JSON.stringify(data)) 
    },
    // 获取订单数量
    getOrderNum(state,data){
        state.orderNum = data
        sessionStorage.setItem("orderNum",JSON.stringify(data)) 
    },
    // 获取输入的搜索
    getSearcNum(state,data){
        state.searchValue = data
        sessionStorage.setItem("searchValue",JSON.stringify(data)) 
    },
    // 修改密码验证
    getVerification(state,data){
        state.isVerification = data
    },
    // 技能培训列表数据
    getSkillsList(state,data){
        state.skillsList = data
        sessionStorage.setItem("skillsList",JSON.stringify(data)) 
    }
    
};

const getters = {};
const modules = {};
export default new Vuex.Store({
    state,
    getters,
    mutations,
    modules,
});