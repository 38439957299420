var WARBLER={
    WARBLER_URL:"https://www.yingyuchat.com/",
    WARBLER_KEFU_ID:"",
    WARBLERBTN_TEXT:"与我聊天",
    WARBLER_ENT:"",
    WARBLERLANG:"cn",
    WARBLEREXTRA: {},
    WARBLERAUTO_OPEN:true,
    WARBLERAUTO_SHOW:false,
    WARBLERWITHOUT_BTN:false,
    WARBLERWITHOUT_BOX:false,
    WARBLERSHOW_GIRL:false,
    WARBLERSIMPLE_ICON:false,
};
WARBLER.launchButtonFlag=false;
WARBLER.titleTimer=0;
WARBLER.titleNum=0;
WARBLER.kefuList=[],
WARBLER.noticeTimer=null;
WARBLER.originTitle=document.title;
WARBLER.chatPageTitle="莺语客服";
WARBLER.kefuName="";
WARBLER.kefuAvator="";
WARBLER.contactInfo="";
WARBLER.kefucolor="";
WARBLER.kefudialogcolor="";
WARBLER.kefudialogfontcolor="";
WARBLER.weixinQrcode="";
WARBLER.ifshowweixinqrcode="";
WARBLER.ifshowcontactinfo="";
WARBLER.kefustyle="";
WARBLER.kefutop="";
WARBLER.kefuweizhi="";
WARBLER.ifshowtop="";
WARBLER.ifshowqq="";
WARBLER.kefuqq="";
WARBLER.init=function(config){
    var _this=this;
    if(typeof config=="undefined"){
        return;
    }

    
   
    if (typeof config.WARBLER_URL!="undefined"){
        this.WARBLER_URL=config.WARBLER_URL.replace(/([\w\W]+)\/$/,"$1");
    }
    this.dynamicLoadCss(this.WARBLER_URL+"/static/css/warbler-front.css?v=1");

    if (typeof config.WARBLER_KEFU_ID!="undefined"){
        this.WARBLER_KEFU_ID=config.WARBLER_KEFU_ID;
    }
    if (typeof config.WARBLERBTN_TEXT!="undefined"){
        this.WARBLERBTN_TEXT=config.WARBLERBTN_TEXT;
    }
    if (typeof config.WARBLER_ENT!="undefined"){
        this.WARBLER_ENT=config.WARBLER_ENT;
    }
    if (typeof config.WARBLEREXTRA!="undefined"){
        this.WARBLEREXTRA=config.WARBLEREXTRA;
    }
    if (typeof config.WARBLERAUTO_OPEN!="undefined"){
        this.WARBLERAUTO_OPEN=config.WARBLERAUTO_OPEN;
    }
    if (typeof config.WARBLERAUTO_SHOW!="undefined"){
        this.WARBLERAUTO_SHOW=config.WARBLERAUTO_SHOW;
        var sUserAgent = navigator.userAgent;
        if (sUserAgent.indexOf('Android') > -1 || sUserAgent.indexOf('iPhone') > -1 || sUserAgent.indexOf('iPad') > -1 || sUserAgent.indexOf('iPod') > -1 || sUserAgent.indexOf('Symbian') > -1) {
            this.WARBLERAUTO_SHOW=false;
        }
    }


    
    if (typeof config.WARBLERWITHOUT_BTN!="undefined"){
        this.WARBLERWITHOUT_BTN=config.WARBLERWITHOUT_BTN;
    }
    if (typeof config.WARBLERSHOW_GIRL!="undefined"){
        this.WARBLERSHOW_GIRL=config.WARBLERSHOW_GIRL;
    }
    if (typeof config.WARBLERWITHOUT_BOX!="undefined"){
        this.WARBLERWITHOUT_BOX=config.WARBLERWITHOUT_BOX;
    }
    if (typeof config.WARBLERSIMPLE_ICON!="undefined"){
        this.WARBLERSIMPLE_ICON=config.WARBLERSIMPLE_ICON;
    }
    var refer=document.referrer?document.referrer:"无";
    this.WARBLEREXTRA.refer=refer;
    this.WARBLEREXTRA.host=document.location.href;
    this.WARBLEREXTRA=JSON.stringify(_this.WARBLEREXTRA);

    this.dynamicLoadJs(this.WARBLER_URL+"/static/js/functions.js?v=1",function(){
        if (typeof config.WARBLERLANG!="undefined"){
            _this.WARBLERLANG=config.WARBLERLANG;
        }else{
            _this.WARBLERLANG=checkLang();
        }
        _this.WARBLEREXTRA=utf8ToB64(_this.WARBLEREXTRA);
    });
    if (typeof $!="function"){
        this.dynamicLoadJs(this.WARBLER_URL+"/static/js/jquery.min.js",function () {
            _this.dynamicLoadJs("https://kehongnetwork.oss-cn-shenzhen.aliyuncs.com/yingyuchat/warblerfrontui/warblerui.js",function () {
                _this.jsCallBack();
            });
        });
    }else{
          //再看版本号
        if ($().jquery == "1.4.2") {
            this.dynamicLoadJs(this.WARBLER_URL + "/static/js/jquery.min.js", function () {
                _this.dynamicLoadJs("https://kehongnetwork.oss-cn-shenzhen.aliyuncs.com/yingyuchat/warblerfrontui/warblerui.js", function () {
                    _this.jsCallBack();
                });
            });
        } else {
            this.dynamicLoadJs("https://kehongnetwork.oss-cn-shenzhen.aliyuncs.com/yingyuchat/warblerfrontui/warblerui.js", function () {
                _this.jsCallBack();
            });
        }
    }
    _this.addEventlisten();
}
WARBLER.jsCallBack=function(){
    this.getKefuStyle();
   

   
}
WARBLER.showKefuBtn=function(){
    var _this = this;
    if (_this.WARBLERWITHOUT_BTN) {
        return;
    }
    if (_this.kefustyle == 1||_this.kefustyle == 0||_this.kefustyle==null) {
        var html = "<ul class='launchButtonBox'>" +
            '<li id="launchButton" class="launchButton">' +
            '<div id="launchIcon" class="launchIcon animateUpDown">1</div> ' +
            '<div class="launchButtonText layui-icon">&#xe606;</div></li>' +
            //如果有配置微信
            ((_this.weixinQrcode != ""&& _this.ifshowweixinqrcode !="1")?"<li id='launchWeixinButton' class='launcheButton removeb'><div class='launchButtonText layui-icon'>&#xe677;</div></li>":"") +
            //如果有配置电话
            ((_this.contactInfo != ""&& _this.ifshowcontactinfo !="1")?"<li id='launchTelButton' class='launcheButton removeb'><div class='launchButtonText layui-icon'>&#xe626;</div></li>":"") +
            //如果有配置qq
            ((_this.kefuqq != ""&& _this.ifshowqq !="1")?"<li  class='launcheButton removeb'><a href='http://wpa.qq.com/msgrd?v=3&uin="+_this.kefuqq+"&site=qq&menu=yes&from=message&isappinstalled=0' target='_blank'><div class='launchButtonText layui-icon'>&#xe676;</div></a></li>":"") +
            //如果有配置top
            ((_this.ifshowtop!="1")?"<li id='launchTopButton' class='launcheButton removeb'><div class='launchButtonText layui-icon'>&#xe604;</div></li>":"") +

            '<div id="launchButtonNotice" class="launchButtonNotice"></div>' +
            '</ul>';


        $('body').append(html);
         
        if(_this.kefutop!=null)
         $(".launchButtonBox").css("top",_this.kefutop+"%");
         if(_this.kefuweizhi!=null)
         {
             if(_this.kefuweizhi=="0")
         $(".launchButtonBox").css("left","0px");
         }

        // if (_this.weixinQrcode != "" && _this.contactInfo != ""&& _this.ifshowcontactinfo !="1"&& _this.ifshowweixinqrcode !="1") {
        //     $("#launchWeixinButton").removeClass("hideli");
        //     $("#launchTelButton").removeClass("hideli");
        // } else if (_this.weixinQrcode == "" && _this.contactInfo != ""&&_this.ifshowweixinqrcode =="1") {
        //     $("#launchTelButton").removeClass("hideli");
        //     $(".launchButtonBox").addClass("bottom39");
        // }
        // else if (_this.weixinQrcode != "" && _this.contactInfo == ""&& _this.ifshowcontactinfo=="1") {
        //     $("#launchWeixinButton").removeClass("hideli").removeClass("removeb").addClass("removebl");;
        //     $(".launchButtonBox").addClass("bottom39");
        // } else if (_this.weixinQrcode == "" && _this.contactInfo == ""&& _this.ifshowcontactinfo=="1"&& _this.ifshowweixinqrcode=="1") {
        //     $("#launchTopButton").removeClass("removebd").addClass("topzero").addClass("removebl");

        //     $(".launchButtonBox").addClass("bottom45");
        // }
        //加载自定义颜色
        var stylecss = "<style>";
        if (_this.kefucolor != "") stylecss = stylecss + ".launchButton, .launcheButton{background: " + _this.kefucolor + "}";
        if (_this.kefudialogcolor != "") stylecss = stylecss + "\r\n.khkefu .layui-kflayer-title{background: " + _this.kefudialogcolor + "}";
        stylecss = stylecss + "</style>";
        $('body').append(stylecss);
    }



    if (_this.kefustyle == 2) {
        var html = "<ul class='launchButtonBox'>" +
            '<li id="launchDoukefu" class="launchButton">' +
            '<div id="launchIcon" class="launchIcon animateUpDown">1</div> ' +
            '<div class="launchButtonText layui-icon">&#xe606;</div></li>' +
            //如果有配置微信
            ((_this.weixinQrcode != ""&& _this.ifshowweixinqrcode !="1")?"<li id='launchWeixinButton' class='launcheButton removeb'><div class='launchButtonText layui-icon'>&#xe677;</div></li>":"") +
            //如果有配置电话
            ((_this.contactInfo != ""&& _this.ifshowcontactinfo !="1")?"<li id='launchTelButton' class='launcheButton removeb'><div class='launchButtonText layui-icon'>&#xe626;</div></li>":"") +
            //如果有配置qq
            ((_this.kefuqq != ""&& _this.ifshowqq !="1")?"<li  class='launcheButton removeb'><a href='http://wpa.qq.com/msgrd?v=3&uin="+_this.kefuqq+"&site=qq&menu=yes&from=message&isappinstalled=0' target='_blank'><div class='launchButtonText layui-icon'>&#xe676;</div></a></li>":"") +
            //如果有配置top
            ((_this.ifshowtop!="1")?"<li id='launchTopButton' class='launcheButton removeb'><div class='launchButtonText layui-icon'>&#xe604;</div></li>":"") +

            '<div id="launchButtonNotice" class="launchButtonNotice"></div>' +
            '</ul>';


        $('body').append(html);
         
        if(_this.kefutop!=null)
         $(".launchButtonBox").css("top",_this.kefutop+"%");
         if(_this.kefuweizhi!=null)
         {
             if(_this.kefuweizhi=="0")
         $(".launchButtonBox").css("left","0px");
         }

        // if (_this.weixinQrcode != "" && _this.contactInfo != ""&& _this.ifshowcontactinfo !="1"&& _this.ifshowweixinqrcode !="1") {
        //     $("#launchWeixinButton").removeClass("hideli");
        //     $("#launchTelButton").removeClass("hideli");
        // } else if (_this.weixinQrcode == "" && _this.contactInfo != ""&&_this.ifshowweixinqrcode =="1") {
        //     $("#launchTelButton").removeClass("hideli");
        //     $(".launchButtonBox").addClass("bottom39");
        // }
        // else if (_this.weixinQrcode != "" && _this.contactInfo == ""&& _this.ifshowcontactinfo=="1") {
        //     $("#launchWeixinButton").removeClass("hideli").removeClass("removeb").addClass("removebl");;
        //     $(".launchButtonBox").addClass("bottom39");
        // } else if (_this.weixinQrcode == "" && _this.contactInfo == ""&& _this.ifshowcontactinfo=="1"&& _this.ifshowweixinqrcode=="1") {
        //     $("#launchTopButton").removeClass("removebd").addClass("topzero").addClass("removebl");

        //     $(".launchButtonBox").addClass("bottom45");
        // }
        //加载自定义颜色
        var stylecss = "<style>";
        if (_this.kefucolor != "") stylecss = stylecss + ".launchButton, .launcheButton{background: " + _this.kefucolor + "}";
        if (_this.kefudialogcolor != "") stylecss = stylecss + "\r\n.khkefu .layui-kflayer-title{background: " + _this.kefudialogcolor + "}";
        stylecss = stylecss + "</style>";
        $('body').append(stylecss);
    }
}
WARBLER.addClickEvent=function(){
    var _this=this;
    $("#launchButton").on("click",function() {
        if(_this.launchButtonFlag){
            return;
        }

        var layBox=$("#layui-kflayer19911116");
        if(layBox.css("display")=="block"){
           return;
       }
        _this.WARBLERAUTO_SHOW=true;
        _this.showKefu();
        $("#launchIcon").text(0).hide();
    });
    $("#launchTopButton").on("click",function() {
        $('body,html').scrollTop(0);
    });
    
    var tipindex=0;
    $("#launchWeixinButton").hover(function(){
        tipindex=kflayer.tips('<div class="launch_wrap"><img src="'+_this.weixinQrcode+'" alt=""></div>', '#launchWeixinButton',{
            tips: [4, '#fff'],time:300000
          });
     },function(){
        kflayer.close(tipindex); 
     });


     $("#launchDoukefu").hover(function(){
         var kefulistr="";
         $.each(_this.kefuList,function(index,curkefu){
            
            kefulistr+='<li class="kefus'+curkefu.status+'"  kefuid="'+curkefu.name+'" kefustatus="'+curkefu.status+'"><div class="icon_box"><img src='+curkefu.avator+' class="infoKfBar"></div><div class="text_box"><h5>'+curkefu.nickname+'</h5></div></li>';
           
        });
        tipindex=kflayer.tips('<div class="mod_hang_appeal_show"><ul>'+kefulistr+'</ul></div>', '#launchDoukefu',{
            tips: [4, '#fff'],time:300000
          });
     },function(){
         $(".mod_hang_appeal_show ul li").on("click", function () {

            //var curkestatus=$(this).attr("kefustatus");
            //if(curkestatus==1) {
                //kflayer.msg("该客服目前不在线!");
                //return;
            //}
            kflayer.close(tipindex); 
             if (_this.WARBLER_KEFU_ID != $(this).attr("kefuid")) {
                $.get(_this.WARBLER_URL+"/custrans_kefu?kefu_id="+$(this).attr("kefuid")+"&kefu_name="+_this.WARBLER_KEFU_ID+"&visitorId="+JSON.parse(localStorage.getItem("visitor_"+_this.WARBLER_ENT+"")).visitor_id+"", function (result) {
                     //_this.otherKefus=result;
                     if (result.code != 200) {
                         alert(result.msg);
                     }
                     else {
                        //_this.kefuAvator=res.result.avator;
                        //_this.chatPageTitle=res.result.nickname;
                        _this.WARBLER_KEFU_ID = $(this).attr("kefuid");
                     }
                     _this.transKefuDialog = false
                 });
             }
             if (_this.launchButtonFlag) {
                 return;
             }
             var layBox=$("#layui-kflayer19911116");
             if(layBox.css("display")=="block"){
                return;
            }
             _this.WARBLERAUTO_SHOW = true;
             _this.showKefu();
             $("#launchIcon").text(0).hide();
         });
        $(".mod_hang_appeal_show").mouseout(function(){
           //kflayer.close(tipindex); 
        });
        
     });

     $(window).scroll(function() {
        kflayer.close(tipindex); 
      });

     $("#launchTelButton").hover(function(){
        tipindex=kflayer.tips('<div class="launch_wrap"><p><a href="tel:'+_this.contactInfo+'">'+_this.contactInfo+'</a></p></div>', '#launchTelButton',{
            tips: [4, '#fff'],time:300000
          });
     },function(){
        kflayer.close(tipindex); 
     });

    $("body").on("click","#launchNoticeClose",function() {
        $("#launchButtonNotice").hide();
    });
    $("body").click(function () {
        clearTimeout(_this.titleTimer);
        document.title = _this.originTitle;
        //剪贴板
        try{
            var selecter = window.getSelection().toString();
            if (selecter != null && selecter.trim() != ""){
                var str=selecter.trim().substr(0,20);
                _this.postMessageToIframe(str);
            }
        } catch (err){
            var selecter = document.selection.createRange();
            var s = selecter.text;
            if (s != null && s.trim() != ""){
                var str=s.trim().substr(0,20);
                _this.postMessageToIframe(str);
            }
        }
    });
    var ms= 1000*2;
    var lastClick = Date.now() - ms;
    $("a,div,p,li").mouseover(function(){
        if (Date.now() - lastClick >= ms) {
            lastClick = Date.now();
            var msg=$(this).text().trim().substr(0,20);
            _this.postMessageToIframe(msg);
        }
    });
    $("body").on("click",".flySimpleIcon",function() {
        _this.showPanel();
    });
}

WARBLER.postMessageToIframe=function(str){
    var obj=document.getElementById('layui-kflayer-iframe19911116');
    if(!obj){
        return;
    }
    var msg={}
    msg.type='inputing_message';
    msg.content=str;
    document.getElementById('layui-kflayer-iframe19911116').contentWindow.postMessage(msg, "*");
}
WARBLER.addEventlisten=function(){
    var _this=this;
    window.addEventListener('message',function(e){
        var msg=e.data;
        if(msg.type=="message"){
            clearInterval(_this.noticeTimer);
            var width=$(window).width();
            if(width>768){
                //_this.flashTitle();//标题闪烁,会影响百度收录标题发生改变
            }
            if (_this.launchButtonFlag||_this.WARBLERSHOW_GIRL){
                return;
            }
            if( msg.data.avator.indexOf("http")<0) msg.data.avator="https://www.yingyuchat.com"+msg.data.avator;
            var welcomeHtml="<div class='flyUser'><img class='flyAvatar' src='"+msg.data.avator+"'/> <span class='flyUsername'>"+msg.data.name+"</span>" +
                "<span id='launchNoticeClose' class='flyClose'>×</span>" +
                "</div>";
            welcomeHtml+="<div id='launchNoticeContent'>"+replaceContent(msg.data.content,_this.WARBLER_URL)+"</div>";
            $("#launchButtonNotice").html(welcomeHtml).show();
            var news=$("#launchIcon").text();
            $("#launchIcon").text(++news).show();
        }
        if(msg.type=="focus"){
            clearTimeout(_this.titleTimer);
            _this.titleTimer=0;
            document.title = _this.originTitle;
        }
    });
    window.onfocus = function () {
        clearTimeout(_this.titleTimer);
        _this.titleTimer=0;
        document.title = _this.originTitle;
    };
}
WARBLER.dynamicLoadCss=function(url){
    var head = document.getElementsByTagName('head')[0];
    var link = document.createElement('link');
    link.type='text/css';
    link.rel = 'stylesheet';
    link.href = url;
    head.appendChild(link);
}
WARBLER.dynamicLoadJs=function(url, callback){
    var head = document.getElementsByTagName('head')[0];
    var script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = url;
    if(typeof(callback)=='function'){
        script.onload = script.onreadystatechange = function () {
            if (!this.readyState || this.readyState === "loaded" || this.readyState === "complete"){
                callback();
                script.onload = script.onreadystatechange = null;
            }
        };
    }
    head.appendChild(script);
}
WARBLER.loadExternalResource=function(url, type) {
    return new Promise(function(resolve, reject){
        let tag;
        if (type === "css") {
            tag = document.createElement("link");
            tag.rel = "stylesheet";
            tag.href = url;
        }
        else if (type === "js") {
            tag = document.createElement("script");
            tag.src = url;
        }
        if (tag) {
            tag.onload =function(){resolve(url)};
            tag.onerror =function(){reject(url)};
            document.head.appendChild(tag);
        }
    });
}
WARBLER.showSimpleIcon=function(){
    var html="<div class='flySimpleIcon'>" +
        "<img src='"+WARBLER.WARBLER_URL+"/static/images/iconchat.png'/> " +
        '</div>';
    $('body').append(html);
}
WARBLER.showGirl=function(){
    var _this=this;
    const live2d_path = "https://cdn.jsdelivr.net/gh/stevenjoezhang/live2d-widget@latest/";
    Promise.all([
        _this.loadExternalResource("https://cdn.jsdelivr.net/npm/font-awesome/css/font-awesome.min.css", "css"),
        _this.loadExternalResource(live2d_path + "live2d.min.js", "js"),
        _this.loadExternalResource(_this.WARBLER_URL + "/static/js/live2d-tips.js", "js")
    ]).then(function(){
        initWidget({
            cdnPath: "https://cdn.jsdelivr.net/gh/fghrsh/live2d_api/"
        });
        document.querySelector("#waifu-tool .fa-comment").addEventListener("click", function(){
            WARBLER.showPanel();
        });
        window.addEventListener('message',function(e){
            var msg=e.data;
            if(msg.type=="message"){
                const tips = document.getElementById("waifu-tips");
                tips.innerHTML = replaceContent(msg.data.content,WARBLER.WARBLER_URL);
                tips.classList.add("waifu-tips-active");
            }
        });
    });
}
WARBLER.getKefuStyle=function(){
    var _this=this;
    $.get(this.WARBLER_URL+"/kefustyle?ent_id="+this.WARBLER_ENT,function(res) {

        //setTimeout(function(){
            //var userInfo="<img style='margin-top: 5px;' class='flyAvatar' src='"+_this.WARBLER_URL+res.result.avatar+"'/> <span class='flyUsername'>"+res.result.username+"</span>"
            //$('.launchButtonText').html(userInfo);
        //},1000);
        _this.kefuAvator=res.kefus[0].avator;
        if( _this.kefuAvator.indexOf("http")<0) _this.kefuAvator="https://www.yingyuchat.com"+_this.kefuAvator;
        _this.kefuName=res.kefus[0].nickname;
        _this.contactInfo=res.result.contactinfo;
        _this.kefucolor=res.result.kefucolor;
        _this.kefudialogcolor=res.result.kefudialogcolor;
        _this.kefudialogfontcolor=res.result.kefudialogfontcolor;
        _this.weixinQrcode=res.result.weixinqrcode;
        _this.chatPageTitle=res.kefus[0].nickname;


        _this.ifshowweixinqrcode=res.result.ifshowweixinqrcode;
        _this.ifshowcontactinfo=res.result.ifshowcontactinfo;
        _this.kefustyle=res.result.kefustyle;
        _this.kefutop=res.result.kefutop;
        _this.kefuweizhi=res.result.kefuweizhi;
        _this.ifshowtop=res.result.ifshowtop;
        _this.ifshowqq=res.result.ifshowqq;
        _this.kefuqq=res.result.kefuqq;
        _this.kefuList=res.kefus

        _this.showKefuBtn();
        _this.addClickEvent();
        _this.getNotice();
        if(_this.WARBLERSHOW_GIRL){
            _this.showGirl();
        }
        if(_this.WARBLERSIMPLE_ICON){
            _this.showSimpleIcon();
        }
    });
}
WARBLER.getNotice=function(){
    var _this=this;
    $.get(this.WARBLER_URL+"/notice?ent_id="+this.WARBLER_ENT,function(res) {

        //setTimeout(function(){
            //var userInfo="<img style='margin-top: 5px;' class='flyAvatar' src='"+_this.WARBLER_URL+res.result.avatar+"'/> <span class='flyUsername'>"+res.result.username+"</span>"
            //$('.launchButtonText').html(userInfo);
        //},1000);
        // _this.kefuAvator=res.result.avatar;
        // _this.kefuName=res.result.username;
        // _this.contactInfo=res.result.contactinfo;
        // _this.kefucolor=res.result.kefucolor;
        // _this.kefudialogcolor=res.result.kefudialogcolor;
        // _this.weixinQrcode=res.result.weixinqrcode;
        // _this.chatPageTitle=res.result.username;

       

        if(_this.WARBLERAUTO_OPEN&&_this.isIE()<=0){
            _this.showKefu();
            $(".launchButtonBox").show();
            _this.launchButtonFlag=false;
        }
        if(_this.WARBLERAUTO_OPEN){
            return;
        }
        if (res.result.welcome != null) {
            var msg = res.result.welcome;
            var len=msg.length;
            var i=0;
            if(len>0){

                _this.noticeTimer=setInterval(function(){
                    if(i>=len||typeof msg[i]=="undefined"||msg[i]==null){
                        clearInterval(_this.noticeTimer);
                        return;
                    }
                    var content = msg[i];
                    if(typeof content.content =="undefined"){
                        return;
                    }

                    if(_this.WARBLERSHOW_GIRL){
                        var tips = document.getElementById("waifu-tips");
                        tips.innerHTML = replaceContent(content.content,WARBLER.WARBLER_URL);
                        tips.classList.add("waifu-tips-active");
                    }


                    var welcomeHtml="<div class='flyUser'><img class='flyAvatar' src='"+_this.WARBLER_URL+content.avator+"'/> <span class='flyUsername'>"+content.name+"</span>" +
                        "<span id='launchNoticeClose' class='flyClose'>×</span>" +
                        "</div>";
                    welcomeHtml+="<div id='launchNoticeContent'>"+replaceContent(content.content,_this.WARBLER_URL)+"</div>";

                    var obj=$("#launchButtonNotice");
                    if(obj[0]){
                        obj[0].innerHTML=welcomeHtml;
                        obj.show();
                    }
                    i++;
                    $("#launchIcon").text(i).show();
                },2000);
            }

        }
    });
}
WARBLER.isIE=function(){
    var userAgent = navigator.userAgent; //取得浏览器的userAgent字符串
    var isIE = userAgent.indexOf("compatible") > -1 && userAgent.indexOf("MSIE") > -1; //判断是否IE<11浏览器
    var isEdge = userAgent.indexOf("Edge") > -1 && !isIE; //判断是否IE的Edge浏览器
    var isIE11 = userAgent.indexOf('Trident') > -1 && userAgent.indexOf("rv:11.0") > -1;
    if(isIE) {
        var reIE = new RegExp("MSIE (\\d+\\.\\d+);");
        reIE.test(userAgent);
        var fIEVersion = parseFloat(RegExp["$1"]);
        if(fIEVersion == 7) {
            return 7;
        } else if(fIEVersion == 8) {
            return 8;
        } else if(fIEVersion == 9) {
            return 9;
        } else if(fIEVersion == 10) {
            return 10;
        } else {
            return 6;//IE版本<=7
        }
    } else if(isEdge) {
        return 'edge';//edge
    } else if(isIE11) {
        return 11; //IE11
    }else{
        return -1;//不是ie浏览器
    }
}
WARBLER.showPanel=function (){
    var width=$(window).width();
    this.WARBLERAUTO_SHOW=true;
    if(this.isIE()>0){
        this.windowOpen();
        return;
    }
    if(width<768){
        this.layerOpen("100%","80%");
        return;
    }
    this.layerOpen("400px","530px");
    this.launchButtonFlag=false;
}
WARBLER.showKefu=function (){
    if (this.launchButtonFlag) return;
    var width=$(window).width();
    if(this.isIE()>0){
        this.windowOpen();
        return;
    }
    // var sUserAgent = navigator.userAgent;
    // if (sUserAgent.indexOf('Android') > -1 || sUserAgent.indexOf('iPhone') > -1 || sUserAgent.indexOf('iPad') > -1 || sUserAgent.indexOf('iPod') > -1 || sUserAgent.indexOf('Symbian') > -1) {
    //     $("body").addClass("khmobilkefu");
    // }
    if(width<768){
        this.layerOpen("100%","80%");
        return;
    }
    this.layerOpen("450px","580px");
    this.launchButtonFlag=false;
    //$(".launchButtonBox").hide();
}
WARBLER.layerOpen=function (width,height){
    if (this.launchButtonFlag) return;
   
    var layBox=$("#layui-kflayer19911116");
    if(layBox.css("display")=="none"){
        layBox.show();
        return;
    }
    var _this=this;
    kflayer.index="19911115";
    kflayer.open({
        type: 2,
        title:'<div class="infoBar"><img src="'+this.kefuAvator+'" class="infoBarLogo"><div class="infoBarText"><p class="infoBarCompany">'+this.chatPageTitle+'</p></div></div>',
        closeBtn: 1, //不显示关闭按钮
        shade: 0,
        skin:"khkefu",
        area: [width, height],
        offset:  _this.kefuweizhi==0?'lb':'rb', //右下角弹出
        anim: 2,
        content: [this.WARBLER_URL+'/chatIndex?kefu_id='+this.WARBLER_KEFU_ID+'&layopen=1&dialogcolor='+encodeURIComponent(_this.kefudialogcolor)+'&dialogfontcolor='+encodeURIComponent(_this.kefudialogfontcolor)+'&lang='+this.WARBLERLANG+'&refer='+encodeURIComponent(window.document.title)+'&url='+encodeURIComponent(document.location.href)+'&ent_id='+this.WARBLER_ENT+'&extra='+this.WARBLEREXTRA , 'yes'], //iframe的url，no代表不显示滚动条
        success:function(){
            
            var layBox=$("#layui-kflayer19911116");
            _this.launchButtonFlag=false;
            if(!_this.WARBLERWITHOUT_BOX&&_this.WARBLERAUTO_SHOW&&layBox.css("display")=="none"){
                layBox.show();
                
            }
        },
        end: function(){
            _this.launchButtonFlag=false;
            $(".launchButtonBox").show(); 
        },
        cancel: function(index, layero){
            $("#layui-kflayer19911116").hide();
            _this.launchButtonFlag=false;
            $(".launchButtonBox").show();
            return false;
        }
    });
}
WARBLER.windowOpen=function (){
   window.open(this.WARBLER_URL+'/chatIndex?kefu_id='+this.WARBLER_KEFU_ID+'&lang='+this.WARBLERLANG+'&refer='+encodeURIComponent(window.document.title)+'&ent_id='+this.WARBLER_ENT+'&extra='+this.WARBLEREXTRA);
}
WARBLER.flashTitle=function () {
    if(this.titleTimer!=0){
        return;
    }
    this.titleTimer = setInterval("WARBLER.flashTitleFunc()", 500);
}
WARBLER.flashTitleFunc=function(){
    this.titleNum++;
    if (this.titleNum >=3) {
        this.titleNum = 1;
    }
    if (this.titleNum == 1) {
        document.title = '【】' + this.originTitle;
    }
    if (this.titleNum == 2) {
        document.title = '【有新消息】' + this.originTitle;
    }
}



export default WARBLER;