<template>
    <div id="app">
        <router-view />
    </div>
</template>

<style lang="scss">
body,
html {
    padding: 0;
    margin: 0;
    width: 100%;
    height: 100%;
}

#app {
    width: 100%;
    height: 100%;
}
</style>
<script>
import WARBLER from './http/yingyu'
export default {
    data() {
        return {}
    },
    created() {
        this.$nextTick(() => {
            // 禁用右键
            document.oncontextmenu = new Function('event.returnValue=false')
            // 禁用选择
            document.onselectstart = new Function('event.returnValue=false')
        })
        document.addEventListener('keydown', function (e) {
            if (e.key == 'F12') {
                e.preventDefault() // 如果按下键F12,阻止事件
            }
        })
    },
    mounted() {
        this.init()
    },
    methods: {
        init() {
            WARBLER.init({
                WARBLER_URL: 'https://www.yingyuchat.com',
                WARBLER_KEFU_ID: 'zhushou413',
                WARBLER_ENT: '591',
                WARBLERAUTO_SHOW: false, //对话框默认一开始就显示，false则为不显示
            })
        },
    },
}
</script>
<style></style>

