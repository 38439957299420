import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import "./assets/css/common.css"
import "./assets/css/yingyu.css"
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import api from "./http/api";
Vue.use(ElementUI)
Vue.config.productionTip = false
Vue.prototype.api = api;
router.beforeEach((to, from, next) => {
     // 让页面回到顶部
      // chrome
      document.body.scrollTop = 0;
      // firefox
      document.documentElement.scrollTop = 0;
      // safari
      window.pageYOffset = 0;
    next()
 })
 Vue.prototype.$keyBoard = function (vm, methodName, code) {
  document.onkeydown = function () {
    let key = window.event.keyCode;
    if (key == code) {
      vm[methodName](code); // 触发methodName事件
    }
  };
}
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
