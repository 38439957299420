import { post, get ,put ,del } from "./axios.js";


let api = {
 
//账号密码登录
loginByPwd: json => {
    return post("/client/login/loginByPwd", json);
},

//发送短信验证码
sendCode: json => {
    return post("/client/login/sendCode", json);
}, 
//验证码登录
phoneLogin: json => {
    return post("/client/login/phoneLogin", json);
},

//注册
registerApi: json => {
    return post("/client/login/register", json);
},

//验证手机号是存在
verifyPhone: json => {
    return post("/client/login/verifyPhone", json);
},

//忘记密码
forgetPwd: json => {
    return post("/client/login/forgetPwd", json); 
},

  
//获取建筑类型
getTreeSelect: json => {
  return get("/client/classification/getTreeSelect", json);
},

//获取系统类型对应的场景
findSystemClassificationScenes: json => {
  return get("/client/classification/findSystemClassificationScenes", json);
},

//通过产品分类获取分类下的商品
findClassificationProduct: json => {
  return get("/client/classification/findClassificationProduct", json);
},


//查询首页数据
findHomeData: json => {
  return get("/client/home/findHomeData", json);
},

//查询分类商品
homeFindClassificationProduct: json => {
  return get("/client/home/findClassificationProduct", json);
},

//查询服务类别
findIServerType: json => {
  return get("/client/server-type/findIServerType", json);
},

//查询服务信息
findServer: json => {
  return get("/client/server/findServer", json);
},

//分页查询商品列表
findGoodsList: json => {
  return get("/client/goods/findGoodsList", json);
},

//查询商品详情
findGoodsDetails: json => {
  return get("/client/goods/findGoodsDetails", json);
},
 
//分页查询商品评论
findPageGoodsEvaluate: json => {
  return get("/client/evaluate/findPageGoodsEvaluate", json);
},

//查询商品评论的好评率和数量
findEvaluateRateNum: json => {
  return get("/client/evaluate/findEvaluateRateNum", json);
},

//收藏商品
addCollec: json => {
  return post("/client/collect/add", json);
},

//取消收藏商品
delCollect: json => {
  return del("/client/collect/delCollect", json);
},

//加入购物车
addShopCartGoods: json => {
  return post("/client/shop-cart/addShopCartGoods", json,1);
},

//分页查询当前用户购物车商品
findAllPageShopCartGoods: json => {
  return get("/client/shop-cart/findAllPageShopCartGoods", json);
},

//修改购物车商品数量
updShopCartGoodsNum: json => {
  return put("/client/shop-cart/updShopCartGoodsNum", json);
},

//修改购物车商品数量
delShopCartGoods: json => {
  return del("/client/shop-cart/delShopCartGoods", json);
},

//查询web端商城搜索关键字
findGoodsSysConfig: json => {
  return get("/client/sys-config/findGoodsSysConfig", json);
},

// 商品详情确认订单
goodsDetailsConfirmOrder: json => {
  return post("/client/order/goodsDetailsConfirmOrder", json,1);
},
// 商品详情创建订单
addFoodsDetailsOrder: json => {
  return post("/client/order/addFoodsDetailsOrder", json);
},

//商品详情创建订单
addGoodsDetailsOrder: json => {
  return post("/client/order/addGoodsDetailsOrder", json,1);
},

//购物车确认订单
shopCartConfirmOrder: json => {
  return post("/client/order/shopCartConfirmOrder", json,1);
},

// 购物车创建订单
addShopCartOrder: json => {
  return post("/client/order/addShopCartOrder", json,1);
},


// 查用户地址
findByUserIdAddress: json => {
  return get("/client/address/findByUserIdAddress", json)
},

// 查用户收藏商品
findCollectionList: json => {
  return get("/client/collect/findCollectionList", json)
},



//查询用户信息
findUserInfo: json => {
  return get("/client/user/findUserInfo", json);
},
// 修改用户信息
updateUserInfo: json => {
  return post("/client/user/updateUserInfo", json)
},
// 修改手机号码
updateUserPhone: json => {
  return post("/client/user/updateUserPhone", json)
},
// 新增认证
insertCertification: json => {
  return post("/client/certification/insertCertification", json)
},
// 查询审核认证状态
findCertificationState: json => {
  return get("/client/certification/findCertificationState", json)
},
// 修改审核认证状态
updateCertificationState: json => {
  return post("/client/certification/updateCertificationState", json)
},


// 查询发票列表
invoiceList: json => {
  return get("/client/invoice/list", json);
},
// 新增发票抬头
insertInvoice: json => {
  return post("/client/invoice/insertInvoice", json)
},
// 修改发票默认状态
invoiceUpdateState: json => {
  return put("/client/invoice/updateState", json)
},
// 修改发票信息
editInvoice: json => {
  return put("/client/invoice/editInvoice", json)
},
// 删除发票信息
deleteInvoice: json => {
  return del("/client/invoice/deleteInvoice", json)
},
// 查询发票记录
findOrderInvoiceLists: json => {
  return get("/client/invoice/findOrderInvoiceLists", json)
},
// 查询发票详情
queryBillById: json => {
  return get("/client/invoice/queryById", json)
},



// 查询设计与施工文章列表
findArticleLists: json => {
  return get("/client/articles/findArticleLists", json)
},
// 查询文章详情
findArticleById: json => {
  return get("/client/articles/queryById", json)
},



// 查询意见反馈类别
selectLists: json => {
  return get("/client/feedbackType/selectLists", json)
},
// 新增意见反馈
insertFeedback: json => {
  return post("/client/feedback/insertFeedback", json)
},
// 查询平台信息
selectInfo: json => {
  return get("/client/platformInfo/selectInfo", json)
},
//查用订单列表
findPageUserOrder: json => {
  return get("/client/order/findPageUserOrder", json);
},

//查询订单详情
findUserOrderDetails: json => {
  return get("/client/order/findUserOrderDetails", json);
},

//查询推荐商品
findRecommendGoods: json => {
  return get("/client/goods/findRecommendGoods", json);
},

//取消订单
cancelOrder: json => {
  return post("/client/order/cancelOrder", json);
},

//确认收货
confirmReceiptGoods: json => {
  return post("/client/order/confirmReceiptGoods", json);
},

//查询子订单信息
findUserOrderItemDetails: json => {
  return get("/client/order/findUserOrderItemDetails", json);
},

//添加商品评论
addGoodsEvaluate: json => {
  return post("/client/evaluate/addGoodsEvaluate", json,1);
},

//添加退款订单
addRefundOrder: json => {
  return post("/client/refund-order/addRefundOrder", json,1);
},
//分页查询用户退款订单
findPageUserRefundOrder: json => {
  return get("/client/refund-order/findPageUserRefundOrder", json);
},

//查询分类
getProductClassification: json => {
  return get("/client/home/getProductClassification", json);
},
// 新增用户地址
insertAddress: json => {
  return post("/client/address/insertAddress", json,1)
},
// 修改地址
updateAddress: json => {
  return post("/client/address/updateAddress", json,1)
},
// 删除地址
deleteAddress: json => {
  return post("/client/address/deleteAddress", json)
},
// 修改地址状态
updateState: json => {
  return post("/client/address/updateState", json)
},

//查询退款订单详情
findRefundOrderDetails: json => {
  return get("/client/refund-order/findRefundOrderDetails", json);
},

//查询退款订单详情
cancellationRefundRequest: json => {
  return put("/client/refund-order/cancellationRefundRequest", json);
},

//获取oss临时凭证
getOssTemporaryCredential: json => {
  return get("/client/oss/getOssTemporaryCredential", json,1);
},


// 查询用户足迹
findByUserIdAndProductIdLists: json => {
  return get("/client/footprint/findByUserIdAndProductIdLists", json)
},
// 清空足迹
batchDelFootprint: json => {
  return del("/client/footprint/batchDelFootprint", json)
},

// 查询用户积分
billSelectByUserId: json => {
  return get("/client/bill/queryByCreateTime",json)
},


// 添加买家退货物流信息
addUserRefundOrderTrack: json => {
  return post("/client/refund-order/addUserRefundOrderTrack", json)
},



// 查询优惠券列表
findByCouponLists: json => {
  return get("/client/coupon/findByCouponLists", json)
},
// 领取优惠券
getCoupon: json => {
  return post("/client/coupon/getCoupon", json)
},
// 查询用户优惠劵
queryByUserId: json => {
  return get("/client/coupon/queryByUserId", json)
},



// 查询用户奖金列表
selectByUserId: json => {
  return get("/client/bonus/selectByUserId", json)
},

// 查询用户奖金
addWithdrawRecord: json => {
  return post("/client/user-withdraw/addWithdrawRecord", json)
},

// 分页查询用户提现记录
findPageWithdrawRecord: json => {
  return get("/client/user-withdraw/findPageWithdrawRecord", json)
},

// 分页获取邀请用户记录
findPageUserInviteRecord: json => {
  return get("/client/invite/findPageUserInviteRecord", json)
},

// 支付商品订单
payGoodsOrder: json => {
  return post("/client/pay/payGoodsOrder", json)
},

//查询微信订单支付状态
wxQueryOrder: json => {
  return get("/client/pay/wxQueryOrder", json)
},

//查询支付宝订单支付状态
aliPayQueryOrder: json => {
  return get("/client/pay/aliPayQueryOrder", json)
},

// 查询友情链接
queryByLists: json => {
  return get("/client/friendshipLink/queryByLists", json)
},

//查询订单数量
findOrderNum: json => {
  return get("/client/order/findOrderNum", json)
},

//查询物流
viewLogistics: json => {
  return get("/client/order/viewLogistics", json)
},
//删除订单
removedOrder: json => {
    return del("/client/order/removedOrder", json);
},

// 添加服务预约
saveReserve: json => {
  return post("/client/reserve/saveReserve", json)
},
// 查询服务案例
queryLists: json => {
  return get("/client/decorative-case/queryLists", json)
},
// 查询技能培训
findSkillsTrainingInfo: json => {
  return get("/client/skill-straining/findSkillsTrainingInfo",json)
},
// 查询建筑服务
queryDecorativeCategory: json => {
  return get("/client/decorative-category/queryDecorativeCategory",json)
},
// 获取装饰服务下拉树列表
treeSelect: json => {
  return get("/client/decorative-category/treeselect",json)
},
// 获取子级详细信息
getInfo: json => {
  return get("/client/decorative-category/getInfo", json)
},
// 查询广告轮播
queryAdvertise: json => {
  return get("/client/home/queryAdvertise",json)
},
//查询加盟店
ConditionalQuery: json => {
  return get("/client/franchise-store/ConditionalQuery", json)
},
//服务城市
selectByProvince: json => {
  return get("/client/franchise-store/selectByProvince", json)
},
//提交售后服务
insertAfterSalesService: json => {
  return post("/client/after-service/insertAfterSalesService", json)
},
//查询服务体系
queryService: json => {
  return get("/client/personnel-management/queryService", json)
},
// 查询技能培训详细
getInfoById: json => {
  return get("/client/skill-straining/getInfoById", json)
},

//申请开票
applyMakingInvoice: json => {
  return post("/client/order/applyMakingInvoice", json)
},
//(新)查询推荐商品
findNewRecommendGoods: json => {
  return get("/client/goods/findNewRecommendGoods", json)
},


};

export default api;
