import Vue from 'vue'
import VueRouter from 'vue-router'
// import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: "/login",
    // path: "/login",
    name: "/login",
    component: resolve => require(["@/views/login/index"], resolve),
    meta: {
    title: "登录"
    }
  },
  {
    path: "/",
    // path: "*",
    name: "home",
    redirect: "/homes/index",
    component: resolve => require(["@/views/Home"], resolve),
    children: [
      {
        path: "/homes/index",
        name: "/index",
        component: resolve => require(["@/views/homes/index"], resolve),
        meta: {
          title: "首页" 
        }
      },
      {
        path: "/homes/ceshi",
        name: "/ceshi",
        component: resolve => require(["@/views/homes/ceshi"], resolve),
        meta: {
          title: "场景" 
        }
      },
      {
        path: "/scene/newScene",
        name: "/newScene",
        component: resolve => require(["@/views/scene/newScene"], resolve),
        meta: {
          title: "新场景" 
        }
      },
      {
        path: "/scene/oldScene",
        name: "/oldScene",
        component: resolve => require(["@/views/scene/oldScene"], resolve),
        meta: {
          title: "旧场景" 
        }
      },
      {
        path: "/homes/serveDetails",
        name: "/serveDetails",
        component: resolve => require(["@/views/homes/serveDetails"], resolve),
        meta: {
          title: "服务详情" 
        }
      },
      {
        path: "/mall/goodsList",
        name: "/goodsList",
        component: resolve => require(["@/views/mall/goodsList"], resolve),
        meta: {
          title: "商品列表" 
        }
      },
      {
        path: "/mall/goodsDetails",
        name: "/goodsDetails",
        component: resolve => require(["@/views/mall/goodsDetails"], resolve),
        meta: {
        title: "商品详情" 
        }
      },
      {
        path: "/cart/index",
        name: "/index",
        component: resolve => require(["@/views/cart/index"], resolve),
        meta: {
        title: "购物车列表" 
        },
      },
      {
        path: "/cart/index",
        name: "/index",
        component: resolve => require(["@/views/cart/index"], resolve),
        meta: {
        title: "购物车列表" 
        },
      },
      {
        path: "/cart/placeOrder",
        name: "/placeOrder",
        component: resolve => require(["@/views/cart/placeOrder"], resolve),
        meta: {
        title: "提交订单" 
        }
      },
      {
        path: "/cart/orderPay",
        name: "/orderPay",
        component: resolve => require(["@/views/cart/orderPay"], resolve),
        meta: {
        title: "支付" 
        }
      },
      
      {
        path: "/cart/index",
        name: "/index",
        component: resolve => require(["@/views/cart/index"], resolve),
        meta: {
        title: "购物车列表" 
        },
      },
      {
        path: "/cart/placeOrder",
        name: "/placeOrder",
        component: resolve => require(["@/views/cart/placeOrder"], resolve),
        meta: {
        title: "提交订单" 
        }
      },
      {
        path: "/cart/orderPay",
        name: "/orderPay",
        component: resolve => require(["@/views/cart/orderPay"], resolve),
        meta: {
        title: "支付" 
        }
      },
      {
        path: "/userInfo/returnGoodsList",
        name: "/returnGoodsList",
        component: resolve => require(["@/views/userInfo/returnGoodsList"], resolve),
        meta: {
        title: "退货退款列表" 
        }
      },
      {
        path: "/userInfo/returnGoods",
        name: "/returnGoods",
        component: resolve => require(["@/views/userInfo/returnGoods"], resolve),
        meta: {
        title: "退货退款" 
        }
      },
      {
        path: "/userInfo/returnGoodsDetails",
        name: "/returnGoodsDetails",
        component: resolve => require(["@/views/userInfo/returnGoodsDetails"], resolve),
        meta: {
        title: "退货退款详情" 
        }
      },
      { 
        path: "/userInfo/orderDetails",
        name: "/userInfo/orderDetails",
        component: resolve => require(["@/views/userInfo/orderDetails"], resolve),
        meta: {
        title: "订单详情"
        }
      },
      {
        path: "/userInfo/orderComment",
        name: "/userInfo/orderComment",
        component: resolve => require(["@/views/userInfo/orderComment"], resolve),
        meta: {
        title: "订单评论"
        }
      },
      {
        path: "/person/index",
        name: "/person",
        component: resolve => require( ["@/views/person/index"], resolve ),
        meta: {
          title: "个人中心"
        }
      },
      {
        path: "/plaza/index",
        name: "/plaza",
        component: resolve => require(["@/views/plaza/index"], resolve),
        meta: {
          title: '领劵广场'
        }
      },
      {
        path: "/build/index",
        name: "/build",
        component: resolve => require(["@/views/build/index"], resolve),
        meta: {
          title: "设计与施工技术"
        },
      },
      {
        path: "/detail/buildDetail",
        name: "/buildDetail",
        component: resolve => require(["@/views/detail/buildDetail"], resolve),
        meta: {
          title: "设计与施工技术详情页"
        }
      },
    ]
  },
  {
    path: "/serviceSystem",
    name: "serviceSystem",
    component: resolve => require(["@/views/serviceSystem"], resolve),
    redirect: "/serviceSystem/home",
    meta: {
      title: "服务体系"
    },
    children: [
        {
            path: "/serviceSystem/home",
            name: "/home",
            component: resolve => require(["@/views/serviceSystem/home"], resolve),
            meta: {
            title: "服务体系"
            },
        },
        {
            path: "/serviceSystem/skill",
            name: "/skill",
            component: resolve => require(["@/views/serviceSystem/skill"], resolve),
            meta: {
            title: "技能培训"
            },
        },
        {
            path: "/serviceSystem/join",
            name: "/join",
            component: resolve => require(["@/views/serviceSystem/join"], resolve),
            meta: {
            title: "加盟申请"
            }, 
        },
        {
            path: "/serviceSystem/afterSales",
            name: "/afterSales",
            component: resolve => require(["@/views/serviceSystem/afterSales"], resolve),
            meta: {
            title: "售后服务"
            },
        },
        // {
        //     path: "/serviceSystem/product",
        //     name: "/product",
        //     component: resolve => require(["@/views/serviceSystem/product"], resolve),
        //     meta: {
        //     title: "产品采购"
        //     },
        // },
        {
            path: "/serviceSystem/service",
            name: "/service",
            component: resolve => require(["@/views/serviceSystem/service"], resolve),
            meta: {
            title: "服务体系"
            },
        },
        {
            path: "/serviceSystem/construct",
            name: "/construct",
            component: resolve => require(["@/views/serviceSystem/construct"], resolve),
            meta: {
            title: "建筑服务"
            },
        },
        {
          path: "/detail/skillsDetail",
          name: "/skillsDetail",
          component: resolve => require(["@/views/detail/skillsDetail"], resolve),
          meta: {
            title: "技能培训详情"
          }
        }
     ]
   
     
  },
]

const router = new VueRouter({
//   mode: 'history',
//   base: process.env.BASE_URL,
  routes
})

router.afterEach((to,from,next)=>{
 
  window.scrollTo(0,0);
 
})
export default router
